import felipeImage from "../../pages/profesionales/kinesiologos/Felipe_Larraguibel.png";

const comunasData = {
  felipe: {
    url: "felipe-larraguibel",
    comun: "Felipe Larraguibel​",
    image: felipeImage,
    id_profesional_fantasma: "6dbc8182-62f0-11ef-8888-678d3c919e34",
  },
  pablo: {
    url: "pablo-larraguibel",
    comun: "Pablo Larrraguibel",
    image: felipeImage,
    id_profesional_fantasma: "6dbc7eeb-62f0-11ef-8888-9f5cfaee7d73",
  },
};

export default comunasData;
