import React, { useState, useCallback } from "react";
import Autosuggest from "react-autosuggest";
import { Box, Typography } from "@mui/material";
import { debounce } from "lodash";

const AddressInput = ({ onAddressChange, onComunaChange }) => {
  const [direccion, setDireccion] = useState("");
  const [sugerencias, setSugerencias] = useState([]);

  const obtenerSugerencias = useCallback(
    debounce((value) => {
      const service = new window.google.maps.places.AutocompleteService();
      service.getPlacePredictions(
        {
          input: value,
          componentRestrictions: { country: "CL" },
        },
        (predictions, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            setSugerencias(
              predictions.map((prediction) => prediction.description)
            );
          }
        }
      );
    }, 300),
    []
  );

  const onDireccionChange = (event, { newValue }) => {
    setDireccion(newValue);
    onAddressChange(newValue);
  };

  const onSugerenciasFetchRequested = ({ value }) => {
    obtenerSugerencias(value);
  };

  const onSugerenciasClearRequested = () => {
    setSugerencias([]);
  };

  const handleDireccionBlur = () => {
    const autocompleteService =
      new window.google.maps.places.AutocompleteService();
    autocompleteService.getPlacePredictions(
      { input: direccion },
      (predictions, status) => {
        if (
          status === window.google.maps.places.PlacesServiceStatus.OK &&
          predictions.length > 0
        ) {
          const placesService = new window.google.maps.places.PlacesService(
            document.createElement("div")
          );
          placesService.getDetails(
            { placeId: predictions[0].place_id },
            (place, status) => {
              if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                const comunaComponent = place.address_components.find(
                  (component) =>
                    component.types.includes("administrative_area_level_3") ||
                    component.types.includes("locality")
                );
                if (comunaComponent) {
                  onComunaChange(comunaComponent.long_name);
                }
              }
            }
          );
        }
      }
    );
  };

  const renderSugerencia = (sugerencia) => (
    <div
      style={{
        cursor: "pointer",
        padding: "7px 0px",
        position: "relative",
      }}
    >
      {sugerencia}
      <div
        style={{
          position: "absolute",
          bottom: 0,
          width: "90%",
          borderBottom: "1px solid #e0e0e0",
        }}
      />
    </div>
  );

  const inputProps = {
    placeholder: "Escribe tu dirección",
    value: direccion,
    onChange: onDireccionChange,
    onBlur: handleDireccionBlur,
    style: {
      border: "none",
      outline: "none",
      background: "transparent",
      paddingLeft: "5px",
      fontFamily: "Quicksand-Regular",
      color: "#4F4F4F",
      fontSize: "16px",
      width: "97%",
      zIndex: 0,
    },
  };

  const theme = {
    suggestionsContainer: {
      position: "absolute",
      borderRadius: "4px",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
      backgroundColor: "#fff",
      width: "100%",
      zIndex: 9999,
    },
  };

  return (
    <>
      <Typography
        sx={{
          pt: 1,
          fontFamily:
            direccion.trim() !== "" ? "Quicksand-Bold" : "Quicksand-Regular",
          fontSize: { xs: "16px", md: "20px", lg: "20px" },
          color: direccion.trim() !== "" ? "#2669A8" : "#4F4F4F",
        }}
      >
        {direccion.trim() !== "" ? "Dirección" : "Dirección*"}
      </Typography>
      <Box
        sx={{
          borderRadius: 2,
          overflow: "hidden",
          backgroundImage: "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
          p: 0.5,
        }}
      >
        <Autosuggest
          suggestions={sugerencias}
          onSuggestionsFetchRequested={onSugerenciasFetchRequested}
          onSuggestionsClearRequested={onSugerenciasClearRequested}
          getSuggestionValue={(sugerencia) => sugerencia}
          renderSuggestion={renderSugerencia}
          inputProps={inputProps}
          theme={theme}
        />
      </Box>
    </>
  );
};

export default AddressInput;
