import React, { useState, useEffect, useRef, useMemo } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  Button,
  Container,
  Grid,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useLocation, useHistory } from "react-router-dom";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import callApi from "../../services/callApi";
import Loader from "../../components/Loader";
import { isMobile } from "react-device-detect";
import { initMercadoPago, Wallet } from "@mercadopago/sdk-react";
import logoImage from "../../assets/newiconos/Teradomus-gris.png";
import AddressInput from "./DireccionDatos";
import PaymentMethodBox from "./PaymentMethod.jsx";

initMercadoPago(process.env.REACT_APP_PUBLIC_TOKEN, { locale: "es-CL" });

const isValidText = (text) => text.length >= 2;
const isValidRut = (rut) => rut.length >= 9;
const isValidEmail = (email) =>
  email.includes("@") && email.includes(".") && email.length >= 5;
const isValidPhone = (phone) => phone.replace("+56", "").length === 9;

const StyledSelect = ({ value, onChange, options, placeholder }) => (
  <Select
    value={value}
    onChange={onChange}
    displayEmpty
    sx={{
      height: "30px",
      width: "100%",
      fontFamily: "Quicksand-Regular",
      color: "#4F4F4F",
      fontSize: "16px",
      backgroundColor: "transparent",
      border: "none", // Aquí eliminamos el borde
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none", // Asegúrate de que el contorno del select también se oculte
      },
      "& .MuiSelect-select": {
        paddingTop: "8px",
        paddingBottom: "8px",
      },
    }}
  >
    <MenuItem value="" disabled>
      {placeholder}
    </MenuItem>
    {options.map((option) => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ))}
  </Select>
);

const months = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

export default function DatosPaciente() {
  let history = useHistory();
  const location = useLocation();
  const MySwal = withReactContent(Swal);
  const [nombre, setNombre] = useState("");
  const nombreCharactersRemaining = 50 - nombre.length;
  const [apellido, setApellido] = useState("");
  const apellidoCharactersRemaining = 50 - apellido.length;
  const [preferenceId, setPreferenceId] = useState(null);
  const [paymentId, setPaymentId] = useState(null);
  const [text, setText] = useState("");
  const textCharactersRemaining = 250 - text.length;

  const [mail, setMail] = useState("");
  const mailCharactersRemaining = 50 - mail.length;

  const [isLoading, setIsLoading] = useState(false);
  const [telefono, setTelefono] = useState("+56");
  const [rut, setRut] = React.useState("");
  const [formatoRut, setFormatoRut] = useState(true);
  const [birthDate, setBirthDate] = useState({ day: "", month: "", year: "" });
  const [sex, setSex] = useState("");
  const [prevision, setPrevision] = useState("");
  const [ApiCrear, setApiCrear] = useState(null);
  const [depto, setDepto] = React.useState("");

  console.log(ApiCrear);

  const createPayment = async () => {
    setIsLoading(true);

    try {
      const apiData = {
        id_profesional_fantasma: location.state.id_kinesiologo,
        id_servicio_fantasma: location.state.id_servicios,
        fecha_agenda: location.state.dates,
        hora_agenda: location.state.hourss,
        paciente_email: mail,
      };
      const result = await callApi("/agenda/pagoCrear", "post", apiData);
      if (
        result &&
        result.message === "Preferencia MercadoPago creada" &&
        result.preference_id
      ) {
        setPreferenceId(result.preference_id);
        setPaymentId(result.payment_id);
        setApiCrear(result);
        handlePagarClick(result.payment_id, result.preference_id);
      } else {
        console.error("Respuesta inesperada:", result);
        MySwal.fire("Respuesta inesperada del servidor", "", "error");
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        console.error("Error de respuesta del servidor:", error.response.data);
        MySwal.fire(
          error.response.data.message || "Error del servidor",
          "",
          "error"
        );
      } else if (error.request) {
        console.error("No se recibió respuesta del servidor");
        MySwal.fire("No se pudo conectar con el servidor", "", "error");
      } else {
        console.error("Error al configurar la petición:", error.message);
        MySwal.fire("Error en la aplicación", "", "error");
      }
    }
  };

  function handleChangeNombre(event) {
    setNombre(event.target.value);
  }
  function handleChangeApellido(event) {
    setApellido(event.target.value);
  }
  const handleChangeDiagnostico = (event) => {
    setText(event.target.value);
  };

  const handleCheckboxChange = (event) => {
    setFormatoRut(!event.target.checked);
  };

  const handleChangeMail = (event) => {
    setMail(event.target.value);
  };

  const handleChangeRut = (event) => {
    const value = event.target.value;
    if (formatoRut) {
      const formattedRut = formatRut(value);
      setRut(formattedRut);
    } else {
      setRut(value);
    }
  };

  const formatRut = (rut) => {
    let cleanRut = rut.replace(/[^0-9kK]+/g, "").toUpperCase();

    if (cleanRut.length > 1) {
      const body = cleanRut.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      const dv = cleanRut.slice(-1);
      cleanRut = body + "-" + dv;
    }

    return cleanRut;
  };

  const handleChangeCelular = (event) => {
    const prefix = "+56";
    let inputValue = event.target.value;

    if (!inputValue.startsWith(prefix)) {
      inputValue = prefix + inputValue.replace(/\D/g, "");
    } else {
      inputValue = prefix + inputValue.slice(prefix.length).replace(/\D/g, "");
    }

    const maxLength = 9;
    inputValue = inputValue.slice(0, prefix.length + maxLength);

    setTelefono(inputValue);
  };
  const handleBirthDateChange = (field) => (event) => {
    setBirthDate({ ...birthDate, [field]: event.target.value });
  };

  const handleSexChange = (event) => {
    setSex(event.target.value);
  };

  const handlePrevisionChange = (event) => {
    setPrevision(event.target.value);
  };
  function handleChangeDepto(event) {
    setDepto(event.target.value);
  }

  const [direccion, setDireccion] = useState("");
  const [comuna, setComuna] = useState("");

  const textseciones = "✨ Ingrese los siguientes datos para agendar";

  const [textoVisible, setTextoVisible] = useState("");

  useEffect(() => {
    let currentIndex = 0;
    let textoAMostrar = textseciones;

    const interval = setInterval(() => {
      if (currentIndex <= textoAMostrar.length) {
        setTextoVisible(textoAMostrar.slice(0, currentIndex));
        currentIndex++;
      } else {
        clearInterval(interval);
      }
    }, 25);
    return () => {
      clearInterval(interval);
    };
  }, [textseciones]);

  const handleGoBack = () => {
    history.goBack();
  };

  const [shouldShowWallet, setShouldShowWallet] = useState(false);
  const [isWalletReady, setIsWalletReady] = useState(false);
  const walletRef = useRef(null);

  const handlePagarClick = async (payment_id, preference_id) => {
    const formatoValido = /^[\d\s+]+$/.test(telefono);
    if (!formatoValido) {
      MySwal.fire("Ingresa un número telefónico válido", "", "info");
      return;
    }
    setIsLoading(true);
    try {
      console.log("Iniciando handlePagarClick");
      const apiData = {
        id_profesional_fantasma: location.state.id_kinesiologo,
        id_servicio_fantasma: location.state.id_servicios,
        fecha_agenda: location.state.dates,
        hora_agenda: location.state.hourss,
        paciente_nombre: nombre,
        paciente_apellido: apellido,
        paciente_rut: rut,
        paciente_es_pasaporte: !formatoRut,
        paciente_email: mail,
        paciente_celular: telefono,
        paciente_direccion: direccion || "No especificada",
        paciente_motivo_consulta: text || "",
        preference_id: location.state.perfil_pago ? preference_id : null,
        payment_id: location.state.perfil_pago ? payment_id : null,
        paciente_sexo: sex,
        paciente_prevision: prevision,
        nacimiento_dia: birthDate.day.toString(),
        nacimiento_mes: birthDate.month,
        nacimiento_ano: birthDate.year.toString(),
        paciente_direccion_oficina: depto,
      };

      console.log("Datos a enviar a la API:", apiData);

      const result = await callApi("/agenda/agendarIntencion", "post", apiData);
      console.log("Respuesta de la API:", result);

      if (result?.statusCode === 200) {
        if (location.state.perfil_pago) {
          console.log("Iniciando proceso de pago");
          console.log(
            "API respondió exitosamente, mostrando Wallet de MercadoPago"
          );
          setShouldShowWallet(true);
          await waitForWalletReady();
          tryAutomaticPayment();
        } else {
          console.log("Redirigiendo a la página de éxito");
          history.push({
            pathname: "/pro/perfil/exito",
            state: { id_intencion: result.id_intencion },
          });
        }
      } else {
        console.error("La API respondió con un error:", result);
        MySwal.fire({
          title: "Error",
          text: `La API respondió con un error. Detalles: ${JSON.stringify(
            result
          )}`,
          icon: "error",
        });
      }
    } catch (error) {
      console.error("Error detallado al procesar la solicitud:", error);
      let errorMessage = "Hubo un problema al procesar su solicitud. ";
      if (error.response) {
        errorMessage += `Respuesta del servidor: ${JSON.stringify(
          error.response.data
        )}`;
      } else if (error.request) {
        errorMessage += "No se recibió respuesta del servidor.";
      } else {
        errorMessage += `Error: ${error.message}`;
      }
      MySwal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };
  const waitForWalletReady = () => {
    return new Promise((resolve) => {
      const checkWallet = () => {
        if (walletRef.current && walletRef.current.querySelector("button")) {
          setIsWalletReady(true);
          resolve();
        } else {
          setTimeout(checkWallet, 100);
        }
      };
      checkWallet();
    });
  };

  const tryAutomaticPayment = () => {
    if (walletRef.current) {
      const mpButton = walletRef.current.querySelector("button");
      if (mpButton) {
        console.log("Intentando clic automático en el botón de MercadoPago");
        mpButton.click();
      } else {
        console.error("No se encontró el botón de MercadoPago");
        showManualPaymentMessage();
      }
    } else {
      console.error("No se encontró la referencia del wallet");
      showManualPaymentMessage();
    }
  };

  const showManualPaymentMessage = () => {
    MySwal.fire({
      title: "Pago Manual Requerido",
      text: "No se pudo iniciar el pago automáticamente. Por favor, haga clic en el botón de pago que aparece debajo.",
      icon: "info",
      confirmButtonText: "Entendido",
    });
  };

  const isFormValid = useMemo(() => {
    return (
      isValidText(nombre) &&
      isValidText(apellido) &&
      isValidRut(rut) &&
      isValidEmail(mail) &&
      isValidPhone(telefono) &&
      direccion.trim() !== "" &&
      birthDate.day &&
      birthDate.month &&
      birthDate.year &&
      sex &&
      prevision
    );
  }, [
    nombre,
    apellido,
    rut,
    mail,
    telefono,
    direccion,
    birthDate,
    sex,
    prevision,
  ]);

  const formatearFechaSinAnio = (fecha) => {
    return fecha.slice(0, -5);
  };

  const transformModalidad = (modalidad) => {
    switch (modalidad) {
      case "consultorio":
        return "Consulta presencial";
      case "domicilio":
        return "Consulta a domicilio";
      case "online":
        return "Consulta online";
      default:
        return modalidad;
    }
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <ScrollToTop />
      <img
        src={logoImage}
        alt="Teradomus Logo"
        style={{
          width: isMobile ? "150px" : "200px",
          height: "auto",
          paddingTop: "15px",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          padding: 15,
        }}
      />
      <Box
        sx={{
          width: isMobile ? "95%" : "40%",
          mx: "auto",
          bgcolor: "#fbfafe",
          boxShadow: 1,
          marginTop: 2,
          borderRadius: 2,
          p: isMobile ? 2 : 3,
          paddingLeft: isMobile ? 0 : 10,
          paddingRight: isMobile ? 0 : 10,
        }}
      >
        <Typography sx={{ ml: 2, fontFamily: "Quicksand-Light" }}>
          Estas agendando con:
        </Typography>
        <Typography
          sx={{
            ml: 2,
            mt: -0.5,
            fontFamily: "Quicksand-Bold",
            fontSize: { xs: "20px", md: "20px", lg: "22px" },
          }}
        >
          {location.state.titulo}
        </Typography>
        <Typography sx={{ ml: 2, fontFamily: "Quicksand-Regular", mt: -0.5 }}>
          {location.state.profesion}
        </Typography>
        <Typography sx={{ ml: 2, fontFamily: "Quicksand-Light", mt: 1 }}>
          El servicio de:
        </Typography>
        <Typography
          sx={{
            ml: 2,
            mt: -0.5,
            fontFamily: "Quicksand-Bold",
            fontSize: { xs: "20px", md: "20px", lg: "22px" },
          }}
        >
          {location.state.servicio_nombre}
        </Typography>
        <Typography sx={{ ml: 2, fontFamily: "Quicksand-Regular", mt: -0.5 }}>
          {location.state.servicio_precios}
        </Typography>
        <Typography sx={{ ml: 2, fontFamily: "Quicksand-Regular", mt: -0.5 }}>
          {transformModalidad(location.state.servicio_modalidad)}
        </Typography>
        <Typography
          sx={{
            ml: 2,
            color: "#8546b1",
            fontFamily: "Quicksand-Bold",
            mt: -0.5,
          }}
        >
          {formatearFechaSinAnio(location.state.fecha_formateada)} {"-"}{" "}
          {location.state.hourss}
        </Typography>
        <Typography
          sx={{ ml: 2, color: "#8546b1", fontFamily: "Quicksand-Bold" }}
        ></Typography>
      </Box>
      <Typography
        textAlign={"center"}
        sx={{
          color: "#007FFF",
          mt: { xs: 1, lg: 3 },
          fontFamily: "Quicksand-Bold",
          fontSize: { xs: "20px", md: "20px", lg: "26px" },
        }}
      >
        Datos del Paciente
      </Typography>
      <Box
        sx={{
          width: { lg: "50.4%", xs: "95%" },
          backgroundColor: "white",
          borderRadius: 3,
          boxShadow: "5px 5px 6px rgba(0, 0, 0, 0.14)",
          border: "1px solid rgba(0, 0, 0, 0.04)",
          mt: { lg: 3, xs: 2 },
          mb: { lg: 5, xs: 5 },
          margin: "0 auto",
        }}
      >
        <FormGroup>
          <Container>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} lg={8}>
                <Box sx={{ mt: 2 }}>
                  <Typography
                    textAlign={"center"}
                    sx={{
                      mt: { xs: 2, lg: 3 },
                      mb: { xs: 3, lg: 5 },
                      fontFamily: "Quicksand-Regular",
                      fontSize: { xs: "16px", md: "16px", lg: "16px" },
                    }}
                  >
                    {textoVisible}
                  </Typography>
                  <Grid item xs={12} lg={12}>
                    <Typography
                      sx={{
                        pt: 1,
                        fontFamily: isValidText(nombre)
                          ? "Quicksand-Bold"
                          : "Quicksand-Regular",
                        fontSize: { xs: "16px", md: "20px", lg: "20px" },
                        color: isValidText(nombre) ? "#2669A8" : "#4F4F4F",
                      }}
                    >
                      {isValidText(nombre) ? "Nombre" : "Nombre*"}
                    </Typography>
                  </Grid>
                  <Box
                    sx={{
                      borderRadius: 2,
                      overflow: "hidden",
                      backgroundImage:
                        "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                      p: 0.5,
                    }}
                  >
                    <input
                      id="name"
                      name="name"
                      onChange={handleChangeNombre}
                      maxLength={50}
                      style={{
                        border: "none",
                        width: "100%",
                        outline: "none",
                        background: "transparent",
                        fontFamily: "Quicksand-Regular",
                        color: "#4F4F4F",
                        fontSize: "16px",
                      }}
                    />
                  </Box>
                  {nombreCharactersRemaining <= 10 && (
                    <Typography
                      variant="caption"
                      sx={{
                        textAlign: "right",
                        color:
                          nombreCharactersRemaining <= 0 ? "red" : "inherit",
                      }}
                    >
                      {nombreCharactersRemaining} caracteres restantes
                    </Typography>
                  )}
                  <Grid item xs={12} lg={12}>
                    <Typography
                      sx={{
                        pt: 1,
                        fontFamily: isValidText(apellido)
                          ? "Quicksand-Bold"
                          : "Quicksand-Regular",
                        fontSize: { xs: "16px", md: "20px", lg: "20px" },
                        color: isValidText(apellido) ? "#2669A8" : "#4F4F4F",
                      }}
                    >
                      {isValidText(apellido) ? "Apellido" : "Apellido*"}
                    </Typography>
                  </Grid>
                  <Box
                    sx={{
                      borderRadius: 2,
                      overflow: "hidden",
                      backgroundImage:
                        "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                      p: 0.5,
                    }}
                  >
                    <input
                      onChange={handleChangeApellido}
                      maxLength={50}
                      style={{
                        border: "none",
                        width: "100%",
                        outline: "none",
                        background: "transparent",
                        fontFamily: "Quicksand-Regular",
                        color: "#4F4F4F",
                        fontSize: "16px",
                      }}
                    />
                  </Box>
                  {apellidoCharactersRemaining <= 10 && (
                    <Typography
                      variant="caption"
                      sx={{
                        textAlign: "right",
                        color:
                          apellidoCharactersRemaining <= 0 ? "red" : "inherit",
                      }}
                    >
                      {apellidoCharactersRemaining} caracteres restantes
                    </Typography>
                  )}
                  <Grid item xs={12} lg={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        component="span"
                        sx={{
                          fontFamily: isValidRut(rut)
                            ? "Quicksand-Bold"
                            : "Quicksand-Regular",
                          fontSize: { xs: "16px", md: "20px", lg: "20px" },
                          color: isValidRut(rut) ? "#2669A8" : "#4F4F4F",
                        }}
                      >
                        {isValidRut(rut) ? "RUT" : "RUT*"}
                      </Typography>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          component="span"
                          sx={{
                            fontFamily: "Quicksand-Regular",
                            fontSize: {
                              lg: "16px",
                              md: "16px",
                              xs: "16px",
                            },
                            color: "#4F4F4F",
                            marginRight: 1,
                          }}
                        >
                          Pasaporte
                        </Typography>
                        <Checkbox
                          checked={!formatoRut}
                          onChange={handleCheckboxChange}
                          name="esPasaporte"
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Box
                    sx={{
                      borderRadius: 2,
                      overflow: "hidden",
                      backgroundImage:
                        "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                      p: 0.5,
                    }}
                  >
                    <input
                      id="rut"
                      name="rut"
                      onChange={handleChangeRut}
                      value={rut}
                      style={{
                        border: "none",
                        width: "100%",
                        outline: "none",
                        background: "transparent",
                        fontFamily: "Quicksand-Regular",
                        color: "#4F4F4F",
                        fontSize: "16px",
                      }}
                      placeholder={
                        formatoRut ? "Ej: 12.345.678-9" : "Ej: Pasaporte123"
                      }
                    />
                  </Box>
                  <Grid item xs={12} lg={12}>
                    <Typography
                      sx={{
                        pt: 1,
                        fontFamily:
                          birthDate.day && birthDate.month && birthDate.year
                            ? "Quicksand-Bold"
                            : "Quicksand-Regular",
                        fontSize: { xs: "16px", md: "20px", lg: "20px" },
                        color:
                          birthDate.day && birthDate.month && birthDate.year
                            ? "#2669A8"
                            : "#4F4F4F",
                      }}
                    >
                      {birthDate.day && birthDate.month && birthDate.year
                        ? "Fecha de nacimiento"
                        : "Fecha de nacimiento*"}
                    </Typography>
                  </Grid>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderRadius: 2,
                      overflow: "hidden",
                      p: 0.5,
                    }}
                  >
                    <Box
                      sx={{
                        width: "16,6%",
                        borderRadius: 1.5,
                        backgroundImage:
                          "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                      }}
                    >
                      <StyledSelect
                        value={birthDate.day}
                        onChange={handleBirthDateChange("day")}
                        options={[...Array(31)].map((_, i) => ({
                          value: i + 1,
                          label: i + 1,
                        }))}
                        placeholder="Día"
                      />
                    </Box>
                    <Box
                      sx={{
                        width: "50%",
                        borderRadius: 1.5,

                        backgroundImage:
                          "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                      }}
                    >
                      <StyledSelect
                        value={birthDate.month}
                        onChange={handleBirthDateChange("month")}
                        options={months.map((month) => ({
                          value: month,
                          label: month.charAt(0).toUpperCase() + month.slice(1),
                        }))}
                        placeholder="Mes"
                      />
                    </Box>
                    <Box
                      sx={{
                        width: "33%",
                        borderRadius: 1.5,

                        backgroundImage:
                          "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                      }}
                    >
                      <StyledSelect
                        value={birthDate.year}
                        onChange={handleBirthDateChange("year")}
                        options={[...Array(125)].map((_, i) => ({
                          value: 2024 - i,
                          label: 2024 - i,
                        }))}
                        placeholder="Año"
                      />
                    </Box>
                  </Box>

                  <Grid item xs={12} lg={12}>
                    <Typography
                      sx={{
                        pt: 1,
                        fontFamily: sex
                          ? "Quicksand-Bold"
                          : "Quicksand-Regular",
                        fontSize: { xs: "16px", md: "20px", lg: "20px" },
                        color: sex ? "#2669A8" : "#4F4F4F",
                      }}
                    >
                      {sex ? "Sexo" : "Sexo*"}
                    </Typography>
                  </Grid>
                  <Box
                    sx={{
                      borderRadius: 2,
                      overflow: "hidden",
                      backgroundImage:
                        "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                      p: 0.1,
                    }}
                  >
                    <StyledSelect
                      value={sex}
                      onChange={handleSexChange}
                      options={[
                        { value: "Masculino", label: "Masculino" },
                        { value: "Femenino", label: "Femenino" },
                        {
                          value: "Prefiero no decirlo",
                          label: "Prefiero no decirlo",
                        },
                      ]}
                      placeholder="Seleccione"
                    />
                  </Box>
                  <Grid item xs={12} lg={12}>
                    <Typography
                      sx={{
                        pt: 1,
                        fontFamily: isValidEmail(mail)
                          ? "Quicksand-Bold"
                          : "Quicksand-Regular",
                        fontSize: { xs: "16px", md: "20px", lg: "20px" },
                        color: isValidEmail(mail) ? "#2669A8" : "#4F4F4F",
                      }}
                    >
                      {isValidEmail(mail) ? "Mail" : "Mail*"}
                    </Typography>
                  </Grid>
                  <Box
                    sx={{
                      borderRadius: 2,
                      overflow: "hidden",
                      backgroundImage:
                        "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                      p: 0.5,
                    }}
                  >
                    <input
                      id="email"
                      value={mail}
                      onChange={handleChangeMail}
                      maxLength={50}
                      style={{
                        border: "none",
                        width: "100%",
                        outline: "none",
                        background: "transparent",
                        fontFamily: "Quicksand-Regular",
                        color: "#4F4F4F",
                        fontSize: "16px",
                      }}
                    />
                  </Box>
                  {mailCharactersRemaining <= 10 && (
                    <Typography
                      variant="caption"
                      sx={{
                        textAlign: "right",
                        color: mailCharactersRemaining <= 0 ? "red" : "inherit",
                      }}
                    >
                      {mailCharactersRemaining} caracteres restantes
                    </Typography>
                  )}
                  <Grid item xs={12} lg={12}>
                    <Typography
                      sx={{
                        pt: 1,
                        fontFamily: isValidPhone(telefono)
                          ? "Quicksand-Bold"
                          : "Quicksand-Regular",
                        fontSize: { xs: "16px", md: "20px", lg: "20px" },
                        color: isValidPhone(telefono) ? "#2669A8" : "#4F4F4F",
                      }}
                    >
                      {isValidPhone(telefono) ? "Celular" : "Celular*"}
                    </Typography>
                  </Grid>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      borderRadius: 2,
                      overflow: "hidden",
                      backgroundImage:
                        "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                    }}
                  >
                    <Box
                      sx={{
                        width: "50px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundImage:
                          "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                        borderRight: "4px solid #fff",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Quicksand-Regular",
                          color: "#4F4F4F",
                          fontSize: "16px",
                        }}
                      >
                        +56
                      </Typography>
                    </Box>
                    <input
                      name="telefono"
                      value={telefono.replace("+56", "")}
                      onChange={handleChangeCelular}
                      style={{
                        border: "none",
                        flex: 1,
                        outline: "none",
                        background: "transparent",
                        paddingLeft: "10px",
                        fontFamily: "Quicksand-Regular",
                        color: "#4F4F4F",
                        fontSize: "16px",
                        height: "28px",
                      }}
                    />
                  </Box>
                  <Grid item xs={12} lg={12}>
                    <Typography
                      sx={{
                        pt: 1,
                        fontFamily: prevision
                          ? "Quicksand-Bold"
                          : "Quicksand-Regular",
                        fontSize: { xs: "16px", md: "20px", lg: "20px" },
                        color: prevision ? "#2669A8" : "#4F4F4F",
                      }}
                    >
                      {prevision ? "Previsión" : "Previsión*"}
                    </Typography>
                  </Grid>
                  <Box
                    sx={{
                      borderRadius: 2,
                      overflow: "hidden",
                      backgroundImage:
                        "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                      p: 0.1,
                    }}
                  >
                    <StyledSelect
                      value={prevision}
                      onChange={handlePrevisionChange}
                      options={[
                        { value: "Isapre", label: "Isapre" },
                        { value: "Fonasa", label: "Fonasa" },
                        { value: "Particular/Otro", label: "Particular/Otro" },
                      ]}
                      placeholder="Seleccione"
                    />
                  </Box>
                  <AddressInput
                    onAddressChange={setDireccion}
                    onComunaChange={setComuna}
                  />
                  <Grid item xs={12} lg={12}>
                    <Typography
                      sx={{
                        pt: 1,
                        fontFamily: "Quicksand-Regular",
                        fontSize: { xs: "16px", md: "20px", lg: "20px" },
                        color: "#4F4F4F",
                      }}
                    >
                      Depto, oficina u otro - opcional
                    </Typography>
                  </Grid>
                  <Box
                    sx={{
                      borderRadius: 2, // Ajusta el valor según tu preferencia
                      overflow: "hidden", // Para recortar los bordes redondeados del TextField
                      backgroundImage:
                        "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                      p: 0.5, // Añade un espacio interno al Box para evitar que el texto toque los bordes
                    }}
                  >
                    <input
                      onChange={handleChangeDepto}
                      style={{
                        border: "none", // Quitamos el borde nativo del input
                        width: "100%", // Ajusta el ancho del input según tus necesidades
                        outline: "none", // Quitamos el contorno al seleccionar el input
                        background: "transparent", // Hacemos el fondo transparente
                        fontFamily: "Quicksand-Regular",
                        color: "#4F4F4F",
                        fontSize: "16px",
                      }}
                    />
                  </Box>
                  <Grid item xs={12} lg={12}>
                    <Typography
                      sx={{
                        pt: 1,
                        fontFamily: "Quicksand-Regular",
                        fontSize: { xs: "16px", md: "20px", lg: "20px" },
                        color: "#4F4F4F",
                      }}
                    >
                      Motivo de consulta - opcional
                    </Typography>
                  </Grid>
                  <Box
                    sx={{
                      borderRadius: 2,
                      backgroundImage:
                        "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                      p: 0.5,
                      zIndex: -9999,
                    }}
                  >
                    <TextareaAutosize
                      onChange={handleChangeDiagnostico}
                      value={text}
                      maxLength={250}
                      style={{
                        border: "none",
                        width: "96%",
                        outline: "none",
                        background: "transparent",
                        fontFamily: "Quicksand-Regular",
                        color: "#4F4F4F",
                        fontSize: "16px",
                        resize: "none",
                        height: "120px",
                      }}
                    />
                  </Box>
                  {textCharactersRemaining <= 10 && (
                    <Typography
                      variant="caption"
                      sx={{
                        textAlign: "right",
                        color: textCharactersRemaining <= 0 ? "red" : "inherit",
                      }}
                    >
                      {textCharactersRemaining} caracteres restantes
                    </Typography>
                  )}
                  <Typography
                    sx={{
                      textAlign: "right",
                      mb: { xs: 3, lg: 4 },
                      pt: 1,
                      fontFamily: "Quicksand-Regular",
                      fontSize: { xs: "14px", md: "16px", lg: "16px" },
                      color: "#4F4F4F",
                    }}
                  >
                    * Obligatorio
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </FormGroup>
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mt: { xs: 3, lg: 2 },
            mb: { xs: 3, lg: 2 },
            width: { xs: "100%", lg: "55%" },
            position: "relative",
          }}
        >
          {location.state.perfil_pago ? (
            <>
              <PaymentMethodBox />
              <Button
                sx={{
                  fontSize: { xs: "14px", md: "16px", lg: "16px" },
                  fontFamily: "Quicksand-Bold",
                  backgroundColor: "#8753b1",
                  "&:hover": { backgroundColor: "#651d87" },
                  borderRadius: 2,
                  zIndex: 2,
                  width: "100%",
                  paddingTop: 2,
                  paddingBottom: 2,
                }}
                variant="contained"
                disabled={!isFormValid || isWalletReady}
                onClick={createPayment}
              >
                {isWalletReady
                  ? "Proceder al Pago"
                  : `Pagar ${location.state.servicio_precios}`}
              </Button>
              {shouldShowWallet && (
                <div
                  ref={walletRef}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: isWalletReady ? 3 : 1,
                  }}
                >
                  <Wallet
                    initialization={{ preferenceId: preferenceId }}
                    customization={{
                      texts: { action: "pay", valueProp: "security_safety" },
                      visual: {
                        borderRadius: "25px",
                        buttonBackground: "blue",
                        hideValueProp: true,
                      },
                    }}
                  />
                </div>
              )}
            </>
          ) : (
            <Button
              sx={{
                fontSize: { xs: "14px", md: "16px", lg: "16px" },
                fontFamily: "Quicksand-Bold",
                backgroundColor: "#8753b1",
                "&:hover": { backgroundColor: "#651d87" },
                borderRadius: 3,
                width: "100%",
              }}
              variant="contained"
              disabled={!isFormValid}
              onClick={handlePagarClick}
            >
              Agendar
            </Button>
          )}
        </Container>
        <Button
          onClick={handleGoBack}
          sx={{
            fontFamily: "Quicksand-Bold",
            fontSize: { xs: "14px", md: "16px", lg: "16px" },
            color: "#007FFF",
            "&:hover": { backgroundColor: "rgba(135, 83, 177, 0.1)" },
            borderRadius: 3,
            textTransform: "none",
          }}
        >
          Volver
        </Button>
      </Box>
    </>
  );
}
